/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				$('.open_login_register, .open_register').magnificPopup({
					'type': 'inline',
					'midClick': true,
					'fixedContentPos': true,
					'fixedBgPos': true,
				});
				$('a.go_top').click( function ( e ) {
					e.preventDefault();
					$('html, body').animate({
						scrollTop: 0
					}, 1500);
				});
				$('.navbar-toggler').magnificPopup({
					'type': 'inline',
					'midClick': true,
					'fixedContentPos': true,
					'fixedBgPos': true,
					callbacks: {
						resize: function() {
							if ( window.innerWidth >= 1260 ) {
								$.magnificPopup.close();
							}
						},
					}
				});
				$('a[href="#openSearch"]').click( function (e) {
					e.preventDefault();
					$(this).parent().find( '.search-form').fadeToggle();
				} );
				$('[name="user_login"]').change( function ( e ) {
					$('#reggister_user_name').val( $(this).val() );
					$('#reggister_user_email').val( $(this).val() );
					$('#reggister_log').val( $(this).val() );
				} );
				$('#open_login_register form').submit( function ( e ) {
					var $form = $(this);
					e.preventDefault();
					$form.find('.alert').remove();
					var data = {
						'action': 'loginWithEmail',
						'email': $form.find('[name="log"]').val(),
						'password': $form.find('[name="pwd"]').val(),
					};
					jQuery.post(ajax_object.ajax_url, data, function(response) {
						console.log( response );
						if ( response.result == 'success' ) {
							$form.prepend('<div class="alert alert-success" style="padding-top: 0.3em; padding-bottom: 0.1em; " role="alert">'+response.msg+'</div>');
							setTimeout( function ( ) {
								location.reload();
							}, 100 );
						}
						else {
							$form.prepend('<div class="alert alert-danger" style="padding-top: 0.3em; padding-bottom: 0.1em; " role="alert">'+response.msg+'</div>');
						}
					});
				} );
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'single_product': {
			getGridSize: function( xs, sm, md, lg, xl, xxl ) {
				return ( window.innerWidth < 544 ) ? xs : ( window.innerWidth < 768 ) ? sm : ( window.innerWidth < 992 ) ? md : ( window.innerWidth < 1260 ) ? lg: ( window.innerWidth < 1440 ) ? xl: xxl;
			},
			initSliders: function ( ) {
				var _this = this;
				$('.releted_products.flexslider').flexslider({
					animation: "slide",
					itemWidth: 230,
					pauseOnAction: false,
					pauseOnHover: true,
					itemMargin: 0,
					minItems: _this.getGridSize(2, 2, 3, 5, 5, 5),
					maxItems: _this.getGridSize(2, 2, 3, 5, 5, 5),
					controlNav: false,
					animationLoop: true,
					prevText: "",
					nextText: "",
					//mousewheel: true,
					start: function(slider){
						$('body').removeClass('loading');
						_this.flexsliderProducts = slider;
						if ( slider.has('.flex-prev.flex-disabled').length ) {
							slider.addClass('pl-0');
						}
						if ( slider.has('.flex-next.flex-disabled').length ) {
							slider.addClass('pr-0');
						}
					}
				});
				$('.thumbnails.flexslider').flexslider({
					animation: "slide",
					itemWidth: 230,
					pauseOnAction: false,
					pauseOnHover: true,
					itemMargin: 0,
					minItems: _this.getGridSize(2, 2, 3, 7, 7, 8),
					maxItems: _this.getGridSize(2, 2, 3, 7, 7, 8),
					controlNav: false,
					animationLoop: true,
					prevText: "",
					nextText: "",
					slideshow: false,
					//mousewheel: true,
					start: function(slider){
						$('body').removeClass('loading');
						_this.flexsliderThumbnails = slider;
						if ( slider.has('.flex-prev.flex-disabled').length ) {
							slider.addClass('pl-0');
						}
						if ( slider.has('.flex-next.flex-disabled').length ) {
							slider.addClass('pr-0');
						}
					}
				});
			},
			flexsliderThumbnails: { vars:{} },
			flexsliderProducts: { vars:{} },
			destroySlider: function() {
				var _this = this;
				if ( isMobile.any ) {
					$('.releted_products.flexslider').removeData("flexslider");
					$('.thumbnails.flexslider').removeData("flexslider");
					_this.initSliders();
				}
				else {
					var gridSizeProducts = _this.getGridSize(2, 2, 3, 5, 5, 5);
					var gridSizeThumbnails = _this.getGridSize(2, 2, 3, 7, 7, 8);
					_this.flexsliderProducts.vars.minItems = gridSizeProducts;
					_this.flexsliderThumbnails.vars.maxItems = gridSizeThumbnails;
				}
			},
			init: function() {
				// JavaScript to be fired on the home pagevar 
				var _this = this;
				var $window = $(window);
				_this.initSliders();

				$window.resize(function() {
					if ( isMobile.any ) {
						_this.destroySlider();
					}
					else {
						var gridSizeProducts = _this.getGridSize(2, 2, 3, 5, 5, 5);
						var gridSizeThumbnails = _this.getGridSize(2, 2, 3, 7, 7, 6);
						_this.flexsliderProducts.vars.minItems = gridSizeProducts;
						_this.flexsliderThumbnails.vars.maxItems = gridSizeThumbnails;
					}
				});
			},
			finalize: function() {
				var _this = this;
				// JavaScript to be fired on the home page, after the init JS
				setTimeout( function () {
					_this.destroySlider();
				}, 1000);
				setTimeout( function () {
					_this.destroySlider();
				}, 2000);
				var $form = $('form.variations_form');
				var yith_wcmg = $('.images');
				
				var yith_wcmg_zoom = $('.yith_magnifier_zoom');
				var yith_wcmg_image = $('.yith_magnifier_zoom img');
				var yith_wcmg_gallery = $('.yith_magnifier_gallery li a');

				var yith_wcmg_default_zoom = yith_wcmg.find('.yith_magnifier_zoom').attr('href');
				var yith_wcmg_default_image = yith_wcmg.find('.yith_magnifier_zoom img').attr('src');
				yith_wcmg_zoom.click( function ( e ) {
					e.preventDefault();
				} );
				yith_wcmg_gallery.click( function ( e ) {
					e.preventDefault();
					var _yith_wcmg_zoom = $(this).data('zoom-image');
					var _yith_wcmg_image = $(this).data('small');
					jQuery('.zoomContainer').remove();
					yith_wcmg_zoom.attr('href', _yith_wcmg_zoom);
					yith_wcmg_image.attr('src', _yith_wcmg_image);
					yith_wcmg_image.attr('data-zoom-image', _yith_wcmg_image);
					yith_wcmg_image.attr('srcset', _yith_wcmg_image);
					yith_wcmg_image.attr('src-orig', _yith_wcmg_image);
					var ez = yith_wcmg_image.data('elevateZoom');	
					ez.imageSrc = _yith_wcmg_zoom;
					ez.currentImage = _yith_wcmg_zoom;
					ez.zoomImage = _yith_wcmg_zoom;
					ez.refresh(0);
				} );
				yith_wcmg_image.elevateZoom({
					gallery: isMobile.any?'woocommerce_product_thumbnails_mobile':'woocommerce_product_thumbnails_pc',
					zoomType: isMobile.any?'inner':'window',
					cursor: 'pointer',
					//galleryActiveClass: 'active',
					borderSize: 1,
					imageCrossfade: true,
					loadingIcon: ''
				});

				$(document).on('found_variation', 'form.variations_form', function (event, variation) {
					jQuery('.zoomContainer').remove();
					
					var image_magnifier = variation.image_link ? variation.image_link : yith_wcmg_default_zoom;
					var image_src = variation.image_src ? variation.image_src : yith_wcmg_default_image;
					yith_wcmg_zoom.attr('href', image_magnifier);
					yith_wcmg_image.attr('src', image_src);
					yith_wcmg_image.attr('srcset', image_src);
					yith_wcmg_image.attr('data-zoom-image', image_magnifier);
					yith_wcmg_image.attr('src-orig', image_src);
					var ez = yith_wcmg_image.data('elevateZoom');	
					ez.imageSrc = image_magnifier;
					ez.currentImage = image_magnifier;
					ez.zoomImage = image_magnifier;
					ez.refresh(0);
					$('.thumbnails.flexslider').removeData("flexslider");
					$('.thumbnails.flexslider').removeData("destroy");
					$('.thumbnails .slides > li').fadeOut(0);
					if ( variation.attributes ) {
						for (var keyAttr in variation.attributes) {
							$('.thumbnails .slides > li[data-'+ keyAttr +'="'+ variation.attributes[keyAttr] +'"]').fadeIn(0);
							setTimeout( function () {
								$('.thumbnails .slides > li').fadeOut(0);
								$('.thumbnails .slides > li[data-'+ keyAttr +'="'+ variation.attributes[keyAttr] +'"]').fadeIn(0);
								$('.thumbnails.flexslider').flexslider({
									animation: "slide",
									selector: ".slides > li:not([style*='display: none'])",
									itemWidth: 230,
									pauseOnAction: false,
									pauseOnHover: true,
									itemMargin: 0,
									minItems: _this.getGridSize(2, 2, 3, 7, 7, 8),
									maxItems: _this.getGridSize(2, 2, 3, 7, 7, 8),
									controlNav: false,
									animationLoop: true,
									prevText: "",
									nextText: "",
									slideshow: false,
								} );
							}, 500 );
						}
					}

				}).on('reset_image', function (event) {
					jQuery('.zoomContainer').remove();
					yith_wcmg_zoom.attr('href', yith_wcmg_default_zoom);
					yith_wcmg_image.attr('src', yith_wcmg_default_image);
					yith_wcmg_image.attr('data-zoom-image', yith_wcmg_default_image);
					yith_wcmg_image.attr('srcset', yith_wcmg_default_image);
					yith_wcmg_image.attr('src-orig', yith_wcmg_default_image);
					var ez = yith_wcmg_image.data('elevateZoom');	
					ez.imageSrc = yith_wcmg_default_zoom;
					ez.currentImage = yith_wcmg_default_zoom;
					ez.zoomImage = yith_wcmg_default_zoom;
					ez.refresh(0);
					$('.thumbnails .slides > li').fadeIn(0);
					$('.thumbnails.flexslider').removeData("flexslider");
					$('.thumbnails.flexslider').removeData("destroy");
					$('.thumbnails.flexslider').flexslider({
						animation: "slide",
						selector: ".slides > li:not([style*='display: none'])",
						itemWidth: 230,
						pauseOnAction: false,
						pauseOnHover: true,
						itemMargin: 0,
						minItems: _this.getGridSize(2, 2, 3, 7, 7, 8),
						maxItems: _this.getGridSize(2, 2, 3, 7, 7, 8),
						controlNav: false,
						animationLoop: true,
						prevText: "",
						nextText: "",
						slideshow: false,
					} );
					console.log( event );
				});
				/*
				$form.on( 'change', '.variations select', function () {
					yith_wcmg_zoom.attr('href', image_magnifier);
					yith_wcmg_image.attr('src', image_src);
					yith_wcmg_image.attr('srcset', image_src);
					yith_wcmg_image.attr('data-zoom-image', image_src);
					yith_wcmg_image.attr('src-orig', image_src);
					console.log( yith_wcmg_image );
					//yith_wcmg.yith_magnifier(yith_magnifier_options);
				} );
				*/
			}
		},
		// Home page
		'home': {
			readyVideo: function (player_id) {
				var froogaloop = $(player_id);
				froogaloop.addEvent('play', function(data) {
					$('.widget_products_slider .flexslider').flexslider("pause");
				});

				froogaloop.addEvent('pause', function(data) {
					$('.widget_products_slider .flexslider').flexslider("play");
				});
			},
			getGridSize: function( xs, sm, md, lg, xl, xxl ) {
				return ( window.innerWidth < 544 ) ? xs : ( window.innerWidth < 768 ) ? sm : ( window.innerWidth < 992 ) ? md : ( window.innerWidth < 1260 ) ? lg: ( window.innerWidth < 1440 ) ? xl: xxl;
			},
			initSliders: function ( ) {
				var _this = this;
				$('.widget_products_slider .flexslider').flexslider({
					animation: "slide",
					//animationLoop: false,
					itemWidth: 192,
					pauseOnAction: false,
					pauseOnHover: true,
					itemMargin: 0,
					minItems: _this.getGridSize(2, 2, 3, 4, 5, 6),
					maxItems: _this.getGridSize(2, 2, 3, 4, 5, 6),
					controlNav: true,
					animationLoop: true,
					directionNav: isMobile.any ? false : true,
					prevText: "",
					nextText: "",
					//mousewheel: true,
					start: function(slider){
						$('body').removeClass('loading');
						_this.flexsliderProducts = slider;
					}
				});
				function addEvent(element, eventName, callback) {
					if (element.addEventListener) {
						element.addEventListener(eventName, callback, false);
					} else {
						element.attachEvent(eventName, callback, false);
					}
				}
				
			},
			flexsliderProducts: { vars:{} },
			destroySlider: function() {
				var _this = this;
				$('.widget_products_slider .flexslider').removeData("flexslider");
				$('.widget_products_slider .flexslider').removeData("destroy");
				_this.initSliders();
			},
			init: function() {
				// JavaScript to be fired on the home pagevar 
				var _this = this;
				var $window = $(window);

				_this.initSliders();
				$window.resize(function() {
					if ( isMobile.any ) {
						//_this.destroySlider();
						var gridSizeProducts = _this.getGridSize( 2, 2, 3, 4, 5, 6 );
						_this.flexsliderProducts.vars.minItems = gridSizeProducts;
						_this.flexsliderProducts.vars.maxItems = gridSizeProducts;
						_this.flexsliderProducts.vars.directionNav = false;
					}
					else {
						var gridSizeProducts = _this.getGridSize( 2, 2, 3, 4, 5, 6 );
						_this.flexsliderProducts.vars.minItems = gridSizeProducts;
						_this.flexsliderProducts.vars.maxItems = gridSizeProducts;
					}
				});
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'finalizar_compra': {
			init: function() {
				// JavaScript to be fired on the about us page
				if ( $('#billing_invoice').val() === 'Factura' ) {
					$('.show_invoice').fadeIn();
				}
				else {
					$('.show_invoice').fadeOut();
					$('#billing_ruc').val('');
					$('#billing_company').val('');
					$('#billing_address_invoice').val('');
				}
				$('#billing_invoice').change( function ( ) {
					if ( $(this).val() === 'Factura' ) {
						$('.show_invoice').fadeIn();
					}
					else {
						$('.show_invoice').fadeOut();
						$('#billing_ruc').val('');
						$('#billing_company').val('');
						$('#billing_address_invoice').val('');
					}	
				} );

				if( $('#billing_district').val() == '(Yo recogeré mi pedido - SIN REPARTO)' ) {
					$('#billing_address_1').val('Jr. Toribio Ara (ex Atahualpa) # 294. Pueblo Libre. Lima-Perú.');
					$('#billing_reference').val('A espaldas de la cuadra 23 de la Av. Brasil, hacia el lado de Pueblo Libre. Por el óvalo de la Av. Brasil (cruce de Av. Brasil con Av. Vivanco).');

					//$("#billing_address_1").prop('disabled', true);
					//$("#billing_reference").prop('disabled', true);

					$("#billing_address_1_field").removeClass('woocommerce-invalid');
					$("#billing_address_1_field label").html('Dirección para que recojas tu pedido');
					$("#billing_reference_field label").html('Referencia');
					$("#billing_address_1").after('<p style="font-size: 1rem; line-height: 1;">Jr. Toribio Ara (ex Atahualpa) # 294. Pueblo Libre. Lima-Perú.</p>');
					$("#billing_reference").after('<p style="font-size: 1rem; line-height: 1;">A espaldas de la cuadra 23 de la Av. Brasil, hacia el lado de Pueblo Libre. Por el óvalo de la Av. Brasil (cruce de Av. Brasil con Av. Vivanco).</p>');
					$('#billing_address_1').fadeOut(0);
					$('#billing_reference').fadeOut(0);
				}
				else {
					$('#billing_address_1').val('');
					$('#billing_reference').val('');

					$("#billing_address_1").prop('disabled', false);
					$("#billing_reference").prop('disabled', false);
					$("#billing_address_1_field label").html('Dirección <abbr class="required" title="obligatorio">*</abbr>');
					$("#billing_reference_field label").html('Referencias (opcional)');
					$("#billing_address_1").next('p').remove();
					$("#billing_reference").next('p').remove();
					$('#billing_address_1').fadeIn(0);
					$('#billing_reference').fadeIn(0);
				}
				$('#billing_district').change( function ( ) {
					if ( $(this).val() == '(Yo recogeré mi pedido - SIN REPARTO)' ) {
						$('#billing_address_1').val('Jr. Toribio Ara (ex Atahualpa) # 294. Pueblo Libre. Lima-Perú.');
						$('#billing_reference').val('A espaldas de la cuadra 23 de la Av. Brasil, hacia el lado de Pueblo Libre. Por el óvalo de la Av. Brasil (cruce de Av. Brasil con Av. Vivanco).');

						//$("#billing_address_1").prop('disabled', true);
						//$("#billing_reference").prop('disabled', true);

						$("#billing_address_1_field").removeClass('woocommerce-invalid');
						$("#billing_address_1_field label").html('Dirección para que recojas tu pedido');
						$("#billing_reference_field label").html('Referencia');
						$("#billing_address_1").after('<p style="font-size: 1rem; line-height: 1;">Jr. Toribio Ara (ex Atahualpa) # 294. Pueblo Libre. Lima-Perú.</p>');
						$("#billing_reference").after('<p style="font-size: 1rem; line-height: 1;">A espaldas de la cuadra 23 de la Av. Brasil, hacia el lado de Pueblo Libre. Por el óvalo de la Av. Brasil (cruce de Av. Brasil con Av. Vivanco).</p>');
						$('#billing_address_1').fadeOut(0);
						$('#billing_reference').fadeOut(0);
					}
					else {
						$('#billing_address_1').val('');
						$('#billing_reference').val('');

						$("#billing_address_1").prop('disabled', false);
						$("#billing_reference").prop('disabled', false);
						$("#billing_address_1_field label").html('Dirección <abbr class="required" title="obligatorio">*</abbr>');
						$("#billing_reference_field label").html('Referencias (opcional)');
						$("#billing_address_1").next('p').remove();
						$("#billing_reference").next('p').remove();
						$('#billing_address_1').fadeIn(0);
						$('#billing_reference').fadeIn(0);
					}
				});
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
